<template>
  <div class="banner-editor-preview justify-center" :key="iframeUpdate" v-if="values">
    <component :is="'iframe'" ref="iframe" class="iframe" :style="iframeStyle"></component>
  </div>
</template>


<style>
  .banner-editor-preview iframe {
    border: 0;
    height: 100%;
    width: 100%;
  }
  .banner-editor-preview {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>

<script>

export default {
  name:'BannerEditorPreview',
  data: () => ({
    iframeUpdate: 0,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({})
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ratio () {
      return this.values.content.height<this.values.content.width ? this.values.content.height/this.values.content.width : this.values.content.width/this.values.content.height
    },
    iframeStyle () {
      const height = this.values.content && this.values.content.height ? this.values.content.height+'px;' : '100%;'
      const width  = this.values.content && this.values.content.width  ? this.values.content.width+'px;'  : '100%;'
      return 'width:'+width+';' + 'height:'+height+'; max-width:100%;'
    },
    ratio_width () {
      return (this.values.content.width)
    },
    ratio_height () {
      return (this.values.content.height)
    },
    image_formats () {
      return [
        {name: 'banner', height: this.values.content.height, width: this.values.content.width },
      ]
    },
    macrosInHtml () {
      let content = ''
      if (this.values.content.html) content += this.values.content.html
      if (this.values.content.style) content += this.values.content.style
      if (this.values.content.script) content += this.values.content.script
      if (content) {
        const regex = /(\$[a-z0-9]{1,30})/gmi
        return ((content).match(regex)) || []
      }
      return []
    },
    // compiledHtml () {
    //   let content = ''
    //   if (this.values.content.html) {
    //     content = this.values.content.html.replace(/(\$[a-z0-9]{1,30})/gmi, (a) => {
    //       if (typeof this.values.content[a] !== 'undefined') {
    //         return this.values.content[a]
    //       }
    //       return a//'<span class="yellow" title="'+ this.values.$i18n.t('BANNER_EDITOR.NO_VALUE')+'">' + a + '</span>'
    //     })
    //   }
    //   return content
    // },
    // compiledStyle () {
    //   let content = ''
    //   if (this.values.content.style) {
    //     content = this.values.content.style.replace(/(\$[a-z0-9]{1,30})/gmi, (a) => {
    //       if (typeof this.values.content[a] !== 'undefined') {
    //         return this.values.content[a]
    //       }
    //       return a
    //     })
    //   }
    //   return content
    // },
    // compiledScript () {
    //   let content = ''
    //   if (this.values.content.script) {
    //     content = this.values.content.script.replace(/(\$[a-z0-9]{1,30})/gmi, (a) => {
    //       if (typeof this.values.content[a] !== 'undefined') {
    //         return this.values.content[a]
    //       }
    //       return a
    //     })
    //   }
    //   return content
    // }
  },

  watch: {
    values: {
      handler (n) {
        this.generateIframe()
      },
      deep: true,
    }
  },

  mounted () {
    this.generateIframe()
  },

  methods: {

    fillData (v) {
      return v.replace(/(\$[a-z0-9_.]{1,30})/gmi, (a) => {
        return this.$nestedData(a.slice(1,a.length), this.data, v)
      })
    },
    compileHtml () {
      let content = ''
      if (this.values.content.html) {
        content = this.values.content.html.replace(/(\$[a-z0-9_]{1,30})/gmi, (a) => {
          if (typeof this.values.content[a] !== 'undefined') {
            return this.fillData(this.values.content[a])
          }
          return a//'<span class="yellow" title="'+ this.values.$i18n.t('BANNER_EDITOR.NO_VALUE')+'">' + a + '</span>'
        })
      }
      return content
    },
    compileStyle () {
      let content = ''
      if (this.values.content.style) {
        content = this.values.content.style.replace(/(\$[a-z0-9_]{1,30})/gmi, (a) => {
          if (typeof this.values.content[a] !== 'undefined') {
            return this.values.content[a]
          }
          return a
        })
      }
      return content
    },
    compileScript () {
      let content = ''
      if (this.values.content.script) {
        content = this.values.content.script.replace(/(\$[a-z0-9_]{1,30})/gmi, (a) => {
          if (typeof this.values.content[a] !== 'undefined') {
            return this.values.content[a]
          }
          return a
        })
      }
      return content
    },

    generateIframe () {
      const height = this.values.content && this.values.content.height ? this.values.content.height+'px;' : '100%;'
      const width  = this.values.content && this.values.content.width  ? this.values.content.width+'px;'  : '100%;'

      const iframe = this.$refs.iframe
      const html = this.compileHtml()
      const script = this.compileScript()
      const style = 'html, body {margin:0; padding:0; height:'+height+'; width:'+width+'; min-height:'+height+'; min-width:'+width+'; overflow:hidden; }\n\n' + this.compileStyle()

      let file = ''
      if (html.includes('<html')) {
        file = html
      } else {
        file = '<html>'
        + '<head></head>'
        + '<body></body>'
        + '</html>'
      }
      if (style) {
        file = file.replace('</head>', '<style>'+style+'</style>\n</head>')
      }
      // if (script) file = file.replace('</body>', '<script>'+script+'</scr'+'ipt>\n</body>')

      let blob = new Blob([file], { type: 'text/html' })
      const url = URL.createObjectURL(blob)
      if (iframe && iframe.src) {
        URL.revokeObjectURL(iframe.src)
        iframe.src = null
      }
      iframe.src = url
      // this.iframeUpdate++
    },


    handleImage (images = {}) {
      this.images = images || {}
      if (images && images.banner) {
        this.values.content.image = images.banner
      }
    },
  }


}

</script>
